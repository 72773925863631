import React from 'react'
import { logoType, getSlug, replaceStr } from "../../../helper"
import IntroCombinationSection from "./layout/IntroCombinationSection"
import MainCombinationSection from "./layout/MainCombinationSection"
import RelatedCombinationSection from "./layout/RelatedCombinationSection"


const Combination = ({ wpCptListener, wpCptPlatform, ruledValue }) => {
    const combinationShortCodeName = [
        /\[listener_name_singular\]/g,
        /\[listener_name_plural\]/g,
        /\[platform_name_singular\]/g,
        /\[platform_name_plural\]/g
    ]
    const combinationName = [
        wpCptListener.cfListener.mainElements.listenerNameSingular,
        wpCptListener.cfListener.mainElements.listenerNamePlural,
        wpCptPlatform.cfPlatform.mainElements.platformNameSingular,
        wpCptPlatform.cfPlatform.mainElements.platformNamePlural,
    ]

    const listenerSlug = `/${getSlug(wpCptListener.uri)}/${wpCptListener.cfListener.mainElements.slug}`

    const introHeadline = [
        {
            headlineValue: replaceStr(
                combinationShortCodeName,
                combinationName,
                ruledValue.formsTagmanagerIntroduction.h1
            ),
            headlineType: 'H1'
        },
        {
            headlineValue: replaceStr(
                combinationShortCodeName,
                combinationName,
                ruledValue.formsTagmanagerIntroduction.h2
            ), headlineType: 'H2'
        }
    ]

    const listenerTabs = {
        listSlug: {
            overviewSlug: listenerSlug,
            datalayersamplesSlug: `${listenerSlug}/${wpCptListener.cfListener.tabs.datalayersamplesSlug}`,
            trackingintegrationsSlug: `${listenerSlug}/${wpCptListener.cfListener.tabs.trackingintegrationsSlug}`,
            usecasesSlug: `${listenerSlug}/${wpCptListener.cfListener.tabs.usecasesSlug}`,
            helpSlug: `${listenerSlug}/${wpCptListener.cfListener.tabs.helpSlug}`,
        },
        hideTabs: wpCptListener.cfListener.mainElements.hideTabs,
        customPostType: 'listener'
    }

    const introBodyText = {
        headlines: [{
            headlineValue: replaceStr(
                combinationShortCodeName,
                combinationName,
                ruledValue.formsTagmanagerIntroduction.h3
            ), headlineType: 'H3'
        }],
        body: [{
            bodyCopy: replaceStr(
                combinationShortCodeName,
                combinationName,
                ruledValue.formsTagmanagerIntroduction.introduction
            )
        }]
    }


    const creatAccoutContent = {
        headline: wpCptListener.cfListener.mainElements.createAccountLiveStatus.createAccountHeadline ?
            wpCptListener.cfListener.mainElements.createAccountLiveStatus.createAccountHeadline :
            'Get started for free',
        subText: wpCptListener.cfListener.mainElements.createAccountLiveStatus.createAccountSubHeadline ?
            wpCptListener.cfListener.mainElements.createAccountLiveStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const creatAccoutComingSoonContent = {
        headline: wpCptListener.cfListener.mainElements.createAccountComingSoonStatus.createAccountHeadline ?
            wpCptListener.cfListener.mainElements.createAccountComingSoonStatus.createAccountHeadline :
            'Create a Trial Account',
        subText: wpCptListener.cfListener.mainElements.createAccountComingSoonStatus.createAccountSubHeadline ?
            wpCptListener.cfListener.mainElements.createAccountComingSoonStatus.createAccountSubHeadline :
            'configure your measurement in minutes'
    }

    const primaryLogo = logoType(
        wpCptListener.cfListener.mainElements.logoFileSvg,
        wpCptListener.cfListener.mainElements.logoFileImage,
        wpCptListener.cfListener.mainElements.useImageInsteadOfSvg
    )

    const secondLogo = logoType(
        wpCptPlatform.cfPlatform.mainElements.logoFileSvg,
        wpCptPlatform.cfPlatform.mainElements.logoFileImage,
        wpCptPlatform.cfPlatform.mainElements.useImageInsteadOfSvg
    )

    const relatedListener = {
        titleH3: wpCptListener.cfListener.relatedAlternative.relatedH3,
        description: replaceStr(
            combinationShortCodeName,
            combinationName,
            wpCptListener.cfListener.relatedAlternative.relatedDescription
        ),
        relatedListeners: wpCptListener.cfListener.relatedAlternative.relatedListeners,
        showRelated: wpCptListener.cfListener.relatedAlternative.showRelated
    }

    const alternatives = {
        titleH3: `Alternatives to ${wpCptListener.cfListener.mainElements.listenerNameSingular}`,
        description: replaceStr(
            combinationShortCodeName,
            combinationName,
            wpCptListener.cfListener.relatedAlternative.overrideAlternativeDescription ? wpCptListener.cfListener.relatedAlternative.customAlternativeDescription : wpCptListener.cfListener.relatedAlternative.relatedDescription
        ),
        relatedListeners: wpCptListener.cfListener.relatedAlternative.alternativeListeners,
        showAlternatives: wpCptListener.cfListener.relatedAlternative.showAlternatives
    }
    return (
        <>
            <IntroCombinationSection
                headlines={introHeadline}
                primaryLogo={primaryLogo}
                secondLogo={secondLogo}
                status={wpCptListener.cfListener.mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={wpCptListener.cfListener.mainElements.comingSoonMessage}
                listenerName={wpCptListener.cfListener.mainElements.listenerNamePlural}
                nameOfEmailList={wpCptListener.cfListener.mainElements.nameOfEmailList}
            />
            <MainCombinationSection
                tabs={listenerTabs}
                status={wpCptListener.cfListener.mainElements.status}
                creatAccoutContent={creatAccoutContent}
                comingSoonMessage={wpCptListener.cfListener.mainElements.comingSoonMessage}
                introBodyText={introBodyText}
                rows={ruledValue.rows}
                combinationShortCodeName={combinationShortCodeName}
                combinationName={combinationName}
                listenerName={wpCptListener.cfListener.mainElements.listenerNamePlural}
                creatAccoutComingSoonContent={creatAccoutComingSoonContent}
                nameOfEmailList={wpCptListener.cfListener.mainElements.nameOfEmailList}
            />
            <RelatedCombinationSection
                relatedListener={relatedListener}
                alternatives={alternatives}
            />
        </>
    )
}

export default Combination
