import React, { useEffect, useState } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Combination from '../components/general/combination/Combination'
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import PopupSchedule from '../components/popupSchedule/PopupSchedule'
// import last from 'lodash/last';

const CombinationTemplate = ({ data, location, ...props }) => {
    const { wpCptListener, wpCptPlatform } = data
    // const slugRule = props.pageContext.slug
    // const overrideTypesRulesCombination = wpCptListener.cfListener.mainElements.overrideTypesRulesCombination;
    // const listenerTypeCurrent = overrideTypesRulesCombination !== null ? wpCptListener.taxListenerFilterTypes.nodes[0].name : wpCptListener.taxListenerTypes.nodes[0].name;

    const ruleCombination = props.pageContext.rule;

    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = ruleCombination
    const { ogTitle, ogDescription, ogType, ogImage, twitterCard } = ruleCombination.defaultSeoFields
    const listenerSlug = getSlug(wpCptListener.uri)

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const breadcrumbItems = [
        {
            name: 'Listeners',
            url: listenerSlug,
        },
        {
            name: wpCptListener.cfListener.mainElements.breadcrumbValue,
            url: `${listenerSlug}/${wpCptListener.cfListener.mainElements.slug}`
        },
        {
            name: 'Integrations',
            url: `${listenerSlug}/${wpCptListener.cfListener.mainElements.slug}/${wpCptListener.cfListener.tabs.trackingintegrationsSlug}`
        },
        {
            name: wpCptPlatform.cfPlatform.mainElements.breadcrumbValue,
        }
    ]

    const combinationShortCodeName = [
        /\[listener_name_singular\]/g,
        /\[listener_name_plural\]/g,
        /\[platform_name_singular\]/g,
        /\[platform_name_plural\]/g
    ]
    const combinationName = [
        wpCptListener.cfListener.mainElements.listenerNameSingular,
        wpCptListener.cfListener.mainElements.listenerNamePlural,
        wpCptPlatform.cfPlatform.mainElements.platformNameSingular,
        wpCptPlatform.cfPlatform.mainElements.platformNamePlural,
    ]

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(
                    combinationShortCodeName,
                    combinationName,
                    titleTag
                )}
                description={replaceStr(
                    combinationShortCodeName,
                    combinationName,
                    metaDescription
                )}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="combination"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <Combination wpCptListener={wpCptListener} wpCptPlatform={wpCptPlatform} ruledValue={ruleCombination} />
        </Layout>
    )
}

export default CombinationTemplate

export const pageQuery = graphql`
    query PageCombinationTemplate($slug: String!, $listenerSlug: String!, $platformSlug: String!) {
        wpCptListener(cfListener: {mainElements: {slug: {eq: $listenerSlug}}}) {
            cfListener {
                mainElements {
                    slug
                    listenerNamePlural
                    listenerNameSingular
                    logoFileSvg
                    status
                    useImageInsteadOfSvg
                    comingSoonMessage
                    nameOfEmailList
                    hideTabs
                    descriptionAboveSearch
                    instructionsAboveSearch
                    breadcrumbValue
                    createAccountLiveStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    createAccountComingSoonStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                }
                relatedAlternative {
                    relatedDescription
                    relatedH3
                    showRelated
                    showAlternatives
                    overrideAlternativeDescription
                    customAlternativeDescription
                    alternativeListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                    relatedListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                }
                tabs {
                    helpSlug
                    usecasesSlug
                    datalayersamplesSlug
                    trackingintegrationsSlug
                }
            }
            taxListenerTypes {
                nodes {
                  name
                }
            }
            taxListenerFilterTypes {
                nodes {
                  name
                }
            }
            uri
        }
        wpCptPlatform(cfPlatform: {tabs: {rules: {elemMatch: {formsTagmanagerIntroduction: {slug: {eq: $slug}}}}}, mainElements: {slug: {eq: $platformSlug}}}) {
            cfPlatform {
                mainElements {
                    breadcrumbValue
                    platformNameSingular
                    platformNamePlural
                    logoFileSvg
                    useImageInsteadOfSvg
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                }
            }
        }
    }
`
