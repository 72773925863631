import React, { Fragment } from 'react'
import TabCustomPostBlock from '../../../Blocks/TabCustomPostBlock'
import HeadlineAndBodyTextBlock from '../../../Blocks/HeadlineAndBodyTextBlock'
import ContentRowBlock from '../../../Blocks/ContentRowBlock'
import CreateAccountBlock from '../../../Blocks/CreateAccountBlock'
import ComingSoonBlock from '../../../Blocks/ComingSoonBlock'
import { replaceStr } from '../../../../helper'

const MainCombinationSection = ({
    tabs,
    introBodyText,
    rows,
    status,
    comingSoonMessage,
    combinationShortCodeName,
    combinationName,
    listenerName,
    nameOfEmailList
}) => {
    return (
        <section className="main-content-wrapper">
            <div className="container">
                <div className="tab-content">
                    <div className="row">
                        <div className="col-lg-12">
                            <TabCustomPostBlock
                                listSlug={tabs.listSlug}
                                customPostType={tabs.customPostType}
                                hideTabs={tabs.hideTabs}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-content">
                    <div className="intro-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <HeadlineAndBodyTextBlock
                                    headlines={introBodyText.headlines}
                                    body={introBodyText.body}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        rows && (
                            <div className="rows-content">
                                <div className="row">
                                    {
                                        rows.map((row, index) => {
                                            row = {
                                                ...row,
                                                h4: replaceStr(combinationShortCodeName, combinationName, row.h4),
                                                description: replaceStr(combinationShortCodeName, combinationName, row.description)
                                            }
                                            return (
                                                <Fragment key={index}>
                                                    <div className="row-item">
                                                        <ContentRowBlock values={row} />
                                                    </div>
                                                    {
                                                        index === 1 && status === 'Coming Soon' && (
                                                            <div className="col-lg-12">
                                                                <div className="form-content-row">
                                                                    <CreateAccountBlock />
                                                                </div>
                                                            </div>
                                                        )

                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    status === 'Coming Soon' ? (
                        <ComingSoonBlock
                            comingSoonMessage={comingSoonMessage}
                            nameOfEmailList={nameOfEmailList}
                        />
                    ) : (
                        <CreateAccountBlock customClass='get-start' />
                    )
                }
            </div>
        </section>
    )
}

export default MainCombinationSection
