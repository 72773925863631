import React from 'react'
import CreateAccountBlock from "../../../Blocks/CreateAccountBlock"
import HeadlineAndBodyTextBlock from "../../../Blocks/HeadlineAndBodyTextBlock"
import ComingSoonBlock from "../../../Blocks/ComingSoonBlock"

const IntroCombinationSection = ({ headlines, primaryLogo, secondLogo, status, comingSoonMessage, nameOfEmailList }) => {
    return (
        <section className="intro-listener">
            <div className="container">
                <div className="intro-content">
                    <div className="row">
                        <div className="col-lg-7">{headlines && <HeadlineAndBodyTextBlock headlines={headlines} />}</div>
                        <div className="col-lg-5">
                            <div className="logo-wrapper">
                                {
                                    secondLogo && <div className="second-logo">
                                        {secondLogo}
                                    </div>
                                }
                                {
                                    primaryLogo && <div className="primary-logo">
                                        {primaryLogo}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    status === 'Coming Soon' ? (
                        <ComingSoonBlock
                            comingSoonMessage={comingSoonMessage}
                            nameOfEmailList={nameOfEmailList}
                        />
                    ) : (
                        <CreateAccountBlock
                            customClass='get-start'
                        />
                    )
                }
            </div>
        </section>
    )
}

export default IntroCombinationSection
